import gsap from "gsap";

export default function imageBlock() {
	const sectionTitles = this.$root.querySelectorAll(".sectionTitles");
	const imageItems = this.$root.querySelectorAll(".imageItem");
	return {
		blockId: 0,
		lastScrollTop: 0,
		direction: "down",
		init() {
			this.checkBlocks();
			this.$watch("blockId", (value, oldValue) => {
				if (value !== oldValue) {
					this.changeBlocks(value, oldValue);
				}
			});
		},
		checkBlocks() {
			this.checkDirection();
			const scrollItems = document.querySelectorAll(".scrollItem");
			const halfWindowHeight = window.innerHeight / 2;
			for (let i = 0; i < scrollItems.length; i++) {
				const trigger = scrollItems[i];
				const top = trigger.getBoundingClientRect().top;
				if (top < halfWindowHeight) {
					this.blockId = i;
				}
			}
		},
		checkDirection() {
			var st = window.pageYOffset || document.documentElement.scrollTop;
			if (st > this.lastScrollTop) {
				this.direction = "down";
			} else {
				this.direction = "up";
			}
			this.lastScrollTop = st <= 0 ? 0 : st;
		},
		changeBlocks(value, oldValue) {
			if (window.innerWidth >= 768) {
				const newBlock = sectionTitles[value];
				const oldBlock = sectionTitles[oldValue];
				const newImage = imageItems[value];
				const oldImage = imageItems[oldValue];
				if (this.direction === "up") {
					this.outUp(oldBlock, oldImage);
					this.inUp(newBlock, newImage);
				} else {
					this.outDown(oldBlock, oldImage);
					this.inDown(newBlock, newImage);
				}
			}
		},
		inUp(text, image) {
			gsap.fromTo(
				text,
				{ yPercent: -100, opacity: 0 },
				{ yPercent: 0, opacity: 1 }
			);
			gsap.fromTo(image, { opacity: 0 }, { opacity: 1 });
		},
		inDown(text, image) {
			gsap.fromTo(
				text,
				{ yPercent: 100, opacity: 0 },
				{ yPercent: 0, opacity: 1 }
			);
			gsap.fromTo(image, { opacity: 0 }, { opacity: 1 });
		},
		outUp(text, image) {
			gsap.fromTo(
				text,
				{ yPercent: 0, opacity: 1 },
				{ yPercent: 100, opacity: 0 }
			);
			gsap.fromTo(image, { opacity: 1 }, { opacity: 0 });
		},
		outDown(text, image) {
			gsap.fromTo(
				text,
				{ yPercent: 0, opacity: 1 },
				{ yPercent: -100, opacity: 0 }
			);
			gsap.fromTo(image, { opacity: 1 }, { opacity: 0 });
		},
	};
}
