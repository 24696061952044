import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export default function about() {
	return {
		articleAtTop: false,
		containerHeight: null,
		scrollFinished: false,
		iOS: false,
		scrollDistance: "+=300%",
		init() {
			this.mobileFuncs();
			this.calculateHeight();
			this.checkArticlePosition();
			this.$nextTick(() => {
				let tl = gsap.timeline({
					scrollTrigger: {
						trigger: this.$refs.shrinkText,
						pin: true,
						start: "top top",
						end: this.scrollDistance,
						scrub: 1,
						onToggle: (self) => this.checkScrolling(self.isActive),
					},
				});
				if (window.innerWidth >= 1024) {
					tl.from(this.$refs.text, { fontSize: 300 });
				} else if (window.innerWidth >= 768) {
					tl.from(this.$refs.text, { fontSize: 135 });
				} else if (window.innerWidth >= 400) {
					tl.from(this.$refs.text, { fontSize: 70 });
				} else {
					tl.from(this.$refs.text, { fontSize: 60 });
				}
			});
		},
		checkArticlePosition() {
			const rootTop = this.$root.getBoundingClientRect().top;
			const top = this.$refs.article.getBoundingClientRect().top;
			const bottom = this.$refs.article.getBoundingClientRect().bottom;
			if (rootTop <= 0 && bottom > 0) {
				if (window.innerWidth < 768) {
					if (top > 40) {
						this.articleAtTop = false;
						this.$store.global.textColour = "white";
						this.$store.global.background = "black";
					} else {
						this.articleAtTop = true;
						this.$store.global.textColour = "black";
						this.$store.global.background = "white";
					}
				} else {
					if (top > 75) {
						this.articleAtTop = false;
						this.$store.global.textColour = "white";
						this.$store.global.background = "black";
					} else {
						this.articleAtTop = true;
						this.$store.global.textColour = "black";
						this.$store.global.background = "white";
					}
				}
			}
		},
		checkScrolling(isActive) {
			if (isActive) {
				this.scrollFinished = false;
			} else {
				this.scrollFinished = true;
			}
		},
		refreshScrollTrigger() {
			if (!this.iOS) {
				this.calculateHeight();
				ScrollTrigger.refresh();
			}
		},
		calculateHeight() {
			this.containerHeight = `${this.$refs.ghostText.offsetHeight}px`;
		},
		mobileFuncs() {
			const userAgent =
				navigator.userAgent || navigator.vendor || window.opera;
			this.iOS =
				/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream
					? true
					: false;
			if (this.iOS) {
				this.scrollDistance = "+=100%";
				ScrollTrigger.normalizeScroll(true);
				// ScrollTrigger.config({
				// 	ignoreMobileResize: true,
				// 	limitCallbacks: true,
				// 	autoRefreshEvents: "DOMContentLoaded,load",
				// });
			}
		},
	};
}
