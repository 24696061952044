export default function contactForm() {
	return {
		submitText: "Submit",
		submitForm() {
			if (this.$validate.isComplete(this.$root)) {
				const contactForm = this.$root;
				const formData = new FormData(contactForm);
				formData.append("action", "contact-form/send");
				this.submitText = "Submitting";
				fetch("/", {
					method: "POST",
					body: formData,
					headers: {
						Accept: "application/json",
					},
				})
					.then((response) => response.json())
					.then((data) => {
						setTimeout(() => {
							this.submitText = "Submitted";
						}, 1000);
						setTimeout(() => {
							this.$root.reset();
							this.submitText = "Submit";
						}, 8000);
					});
			}
		},
	};
}
