export default function footer() {
	const footer = document.querySelector("footer");
	return {
		footerHeight: null,
		fixedFooter: null,
		init() {
			this.$nextTick(() => {
				this.updateFooterHeight();
			});
		},
		updateFooterHeight() {
			if (window.innerWidth > 640) {
				this.footerHeight = `${footer.offsetHeight}px`;
				if (window.innerHeight < footer.offsetHeight) {
					this.fixedFooter = false;
				} else {
					this.fixedFooter = true;
				}
			} else {
				this.fixedFooter = false;
			}
		},
	};
}
