import MoveTo from "moveto";

const moveTo = new MoveTo({
	duration: 200,
	easing: "easeOutQuart",
});
const body = document.querySelector("body");

export default function header() {
	return {
		open: false,
		scrollToTop() {
			if (this.open) {
				this.open = false;
				moveTo.move(body);
			} else {
				moveTo.move(body);
			}
		},
	};
}
