import gsap from "gsap";
import MoveTo from "moveto";

const moveTo = new MoveTo({
	duration: 200,
	easing: "easeOutQuart",
});

export const global = (Alpine) =>
	Alpine.store("global", {
		textColour: "white",
		background: "none",
		toBlack: {
			["x-init"]: "$store.global.changeTextColour('black',$el)",
			["@scroll.window"]() {
				this.$store.global.changeTextColour("black", this.$el);
				this.$store.global.changeBackground("none", this.$el);
			},
		},
		toWhite: {
			["x-init"]: "$store.global.changeTextColour('white',$el)",
			["@scroll.window"]() {
				this.$store.global.changeTextColour("white", this.$el);
				this.$store.global.changeBackground("none", this.$el);
			},
		},
		toWhiteBlack: {
			["x-init"]: "$store.global.changeTextColour('white',$el)",
			["@scroll.window"]() {
				this.$store.global.changeTextColour("white", this.$el);
				this.$store.global.changeBackground("black", this.$el);
			},
		},
		toBlackWhite: {
			["x-init"]: "$store.global.changeTextColour('white',$el)",
			["@scroll.window"]() {
				this.$store.global.changeTextColour("black", this.$el);
				this.$store.global.changeBackground("white", this.$el);
			},
		},
		toBlueWhite: {
			["x-init"]: "$store.global.changeTextColour('white',$el)",
			["@scroll.window"]() {
				this.$store.global.changeTextColour("black", this.$el);
				this.$store.global.changeBackground("blue", this.$el);
			},
		},
		links: [],
		init() {
			this.getLinks();
		},
		getLinks() {
			const sections = document.querySelectorAll('[id^="section-"]');
			sections.forEach((section) => {
				this.links.push(section.dataset.title);
			});
		},
		scrollTo(section) {
			const makeCamel = (str) =>
				str
					.toLowerCase()
					.replace(/[^a-zA-Z0-9]+(.)/g, (m, str) =>
						str.toUpperCase()
					);
			const target = document.getElementById(
				`section-${makeCamel(section)}`
			);
			moveTo.move(target);
		},
		scrollToNext() {
			const builder = document.querySelector("#builder");
			moveTo.move(builder);
		},
		changeTextColour(value, el) {
			if (window.scrollY != 0) {
				const top = el.getBoundingClientRect().top;
				const bottom = el.getBoundingClientRect().bottom;
				if (window.innerWidth < 768) {
					if (top < 40 && bottom > 40) {
						this.textColour = value;
					}
				} else {
					if (top < 75 && bottom > 75) {
						this.textColour = value;
					}
				}
			}
		},
		changeBackground(value, el) {
			if (window.scrollY != 0) {
				const top = el.getBoundingClientRect().top;
				const bottom = el.getBoundingClientRect().bottom;
				if (top < 1 && bottom > 1) {
					this.background = value;
				}
			}
		},
		staggerFadeIn(nodes, el) {
			const scopedNodes = el.querySelectorAll(nodes);
			setTimeout(() => {
				gsap.to(scopedNodes, {
					opacity: 1,
					stagger: 0.1,
					duration: 0.5,
				});
			}, 500);
		},
	});
